// Unique DOM Id function
var counter = 1
export const uniqueDomId = function (prefix = '') {
  return prefix + counter++
}

/**
 * Fonction native jquery qui affiche un loader à un élément en cours de chargement (durant un import dynamique par exemple)
 * @param {boolean} i = true Affiche ou non le loader
 */
$.fn.load = function (i = true) {
  if (i) {
    this.find('.load').stop(true, true).animate({ opacity: 0.5 }, 200)
    this.find('input, button, textarea, select').prop('disabled', true)
    $(this.find('.processing')[0]).stop(true, true).show().animate({ opacity: 1 }, 200)
  } else {
    this.addClass('loaded')
    this.find('.load').stop(true, true).animate({ opacity: 1 }, 200)
    this.find('input, button, textarea, select').prop('disabled', false)
    $(this.find('.processing')[0])
      .stop(true, true)
      .animate({ opacity: 0 }, 200, function () {
        $(this).hide()
      })
  }
}

// Display a loader inside a content (used .processing and .load)
export const loading = function (el, display = true) {
  //const content = el.querySelector('.load')
  const loader = el.querySelector('.processing')
  if (loader) {
    let id = ''
    if (el.hasAttribute('data-loading')) id = el.getAttribute('data-loading')
    else {
      id = uniqueDomId()
      el.setAttribute('data-loading', id)
    }
    if (display) {
      show(loader, id)
      //hide(content)
    } else {
      hide(loader, id)
      //show(content)
    }
  }
}

// Show an element
var showing = []
export const show = function (elem, id) {
  elem.classList.remove('visually-hidden')
  elem.classList.add('is-visible')
  clearTimeout(showing[id])
  showing[id] = window.setTimeout(function () {
    //console.log(elem, ' shown')
  }, 500)
}

// Hide an element
//var hiding = []
export const hide = function (elem, id) {
  elem.classList.remove('is-visible')
  clearTimeout(showing[id])
  showing[id] = window.setTimeout(function () {
    elem.classList.add('visually-hidden')
  }, 500)
}

// Wait while GMap API is loading
export function dtectMap(action) {
  if ($('body').hasClass('maploaded')) {
    //console.log('maploaded')
    action()
  } else {
    setTimeout(function () {
      dtectMap(action)
    }, 200)
  }
}

// Number format number
export function numberFormat(number, decimals, decPoint, thousandsSep) {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
  const dec = typeof decPoint === 'undefined' ? '.' : decPoint
  let s = ''
  const toFixedFix = function (n, prec) {
    const k = Math.pow(10, prec)
    return '' + Math.round(n * k) / k
  }
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}
